/* eslint-disable import/no-unresolved */
import React from 'react'
import { Vacature as Post } from 'components/elements'

const Vacature = ({ posts }) => {
  return (
    <div className="row">
      {posts.map(({ node }) => (
        <div key={node.wordpress_id} className="mb-3 mb-lg-0 col-lg-4 d-flex justify-content-center">
          <Post fields={node} />
        </div>
      ))}
    </div>
  )
}

export default Vacature