/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Blog
import Vacature from 'components/related/Vacature'
import BlogIk, { BlogConsumer } from 'components/shared/BlogIk'

// Components
import { SEO, ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'
import Layout from 'components/layout/Layout'

import Plaatjie from '@ubo/plaatjie'
import KnowItem from '../components/elements/KnowItem'

const Hero = styled.section`
  margin-top: -10px;

  @media screen and (max-width: 992px){
    background-size: cover!important;
  }
`

const HeroContent = styled.div`
  padding-top: 155px;
  padding-bottom: 90px;
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {

  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <Hero className="hero-image-small" style={{
        backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
      }}>
        <HeroContent className="container px-3">
          <div className="mt-5 mb-3">
            <ParseContent content={acf.banner.description} />
          </div>
          <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
        </HeroContent>
      </Hero>

      {acf.description && (
        <section className="description">
          <div className="container">
            <div className="row py-3 pt-lg-5 pb-lg-3">
              <div className="col-12">
                <ParseContent content={acf.description} />
              </div>
            </div>
          </div>
        </section>
      )}

      {acf.knowitem && (
        <KnowItem id={acf.knowitem} />
      )}

      <div className="container pb-lg-1 mb-5">
        <BlogIk
          settings={{
            limit: parseFloat(acf.meta.amount_posts),
            postType: 'vacatures',
            usePagination: false
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts }) => {
              return (
                <div>

                  {hasPosts && (
                    <Vacature posts={posts} />
                  )}

                  {/* {!hasPosts && (
                    <ParseContent className="py-5" content="Geen vacatures gevonden" />
                  )} */}
                </div>
              )
            }}
          </BlogConsumer>
        </BlogIk>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          background {
          localFile {
            publicURL
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                src
              }
            }
          }
        }
        }

        meta {
          amount_posts
        }

        description
        knowitem
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate